import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import PhotoStrip from '../components/PhotoStrip'

import styled from 'styled-components'
import {
  VW,
  VARIABLE_FUNCTION
} from '../styles'
import FlavText from '../components/FlavText'
import { LayoutContext } from '../contexts'

const Text = ({
  data: {
    contentfulTextPage: {
      seoTitle,
      seoDescription,
      flavouredContent: {
        type,
        flavLeft: {
          json: flavLeft
        },
        flavRight: {
          json: flavRight 
        }
      },
      imageTicker
    }
  },
}) => {
  const { headerBanner } = useContext(LayoutContext)

  return (
    <StyledText headerBanner={headerBanner}>
      <SEO
        title={seoTitle}
        description={seoDescription}
        slug=' ' />
      <PhotoStrip images={imageTicker.map((i) => ({
        src: i.resize.src,
        width: i.resize.width,
        height: i.resize.height,
        alt: i.description || ''
      }))} />
      <FlavText
        type={type}
        left={flavLeft}
        right={flavRight}
        bottomSpacing />
    </StyledText>
  )
}

const StyledText = styled.div`
  margin: ${props => VARIABLE_FUNCTION(props.headerBanner).mobileHeader} 0 120px;

  @media (min-width: ${VW.desktop}) {
    margin: ${props => VARIABLE_FUNCTION(props.headerBanner).desktopHeader} 0 290px;
  }
`

export const PageQuery = graphql`
  query Text($id: String!) {
    contentfulTextPage(id: { eq: $id }) {
      seoTitle
      seoDescription
      slug
      flavouredContent {
        type
        flavLeft {
          json
        }
        flavRight {
          json
        }
      }
      imageTicker {
        description
        resize(height: 512) {
          src
          width
          height
        }
      }
    }
  }
`

export default Text
